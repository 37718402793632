var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("ck-loader")
    : _c(
        "div",
        [
          _c("gov-heading", { attrs: { tag: "h3", size: "m" } }, [
            _vm._v("Collections")
          ]),
          _vm._t("default"),
          _c(
            "gov-form-group",
            [
              _c("gov-label", { attrs: { for: "filter[name]" } }, [
                _vm._v("Search by Collection name")
              ]),
              _c("gov-input", {
                attrs: {
                  id: "filter[name]",
                  name: "filter[name]",
                  type: "search"
                },
                model: {
                  value: _vm.filters.name,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "name", $$v)
                  },
                  expression: "filters.name"
                }
              })
            ],
            1
          ),
          _c(
            "gov-button",
            {
              on: {
                click: function($event) {
                  _vm.filters.name = ""
                }
              }
            },
            [_vm._v("Clear")]
          ),
          _c(
            "gov-form-group",
            [
              _c(
                "gov-checkboxes",
                { attrs: { invalid: _vm.error } },
                [
                  _c("ck-node-checkboxes", {
                    attrs: {
                      nodes: _vm.filteredCollections,
                      checked: _vm.enabledCollections,
                      disabled: _vm.disabled,
                      filteredNodeIds: true
                    },
                    on: { update: _vm.onUpdate }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }