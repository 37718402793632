var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.sortedContent, function(ref) {
      var sectionId = ref[0]
      var section = ref[1]
      return _c(
        "div",
        { key: sectionId },
        [
          _c("gov-heading", { attrs: { tag: "h3", size: "m" } }, [
            _vm._v(_vm._s(section.label))
          ]),
          Object.keys(section).includes("title")
            ? _c("ck-text-input", {
                attrs: {
                  value: section.title,
                  id: sectionId + "-title",
                  label: "Title",
                  type: "text",
                  error: _vm.errors.get("content_" + sectionId + "_title")
                },
                on: {
                  input: function($event) {
                    return _vm.onChangeTitle(sectionId, $event)
                  }
                }
              })
            : _vm._e(),
          _vm._l(section.content, function(content, index) {
            return _c(
              "div",
              { key: sectionId + "-content-" + index },
              [
                content.type === "copy"
                  ? _c("ck-wysiwyg-input", {
                      attrs: {
                        value: content.value,
                        id: sectionId + "-copy-" + index,
                        label: section.label,
                        hint: section.hint,
                        error: _vm.errors.get(
                          "content." + sectionId + ".content." + index
                        ),
                        large: "",
                        maxlength: 60000
                      },
                      on: {
                        input: function($event) {
                          return _vm.onChangeCopy(sectionId, index, $event)
                        }
                      }
                    })
                  : content.type === "cta"
                  ? _c("ck-call-to-action", {
                      attrs: {
                        "call-to-action": content,
                        id: sectionId + "-cta-" + index,
                        error: _vm.errors.get(
                          "content." + sectionId + ".content." + index
                        )
                      },
                      on: {
                        input: function($event) {
                          return _vm.onChangeCalltoAction(
                            sectionId,
                            index,
                            $event
                          )
                        }
                      }
                    })
                  : content.type === "video"
                  ? _c("ck-video-embed", {
                      attrs: {
                        video: content,
                        id: sectionId + "-video-" + index,
                        error: _vm.errors.get(
                          "content." + sectionId + ".content." + index
                        )
                      },
                      on: {
                        input: function($event) {
                          return _vm.onChangeVideo(sectionId, index, $event)
                        }
                      }
                    })
                  : _vm._e(),
                _c(
                  "gov-button",
                  {
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.addCopy(sectionId, index)
                      }
                    }
                  },
                  [_vm._v("Add Copy here")]
                ),
                _vm._v(" \n      "),
                _c(
                  "gov-button",
                  {
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.addCallToAction(sectionId, index)
                      }
                    }
                  },
                  [_vm._v("Add Call to action here")]
                ),
                _vm._v(" \n      "),
                _c(
                  "gov-button",
                  {
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.addVideo(sectionId, index)
                      }
                    }
                  },
                  [_vm._v("Add Video here")]
                ),
                _vm._v(" \n      "),
                index > 0
                  ? _c(
                      "span",
                      [
                        _c(
                          "gov-button",
                          {
                            attrs: { type: "button", warning: true },
                            on: {
                              click: function($event) {
                                return _vm.removeContent(sectionId, index)
                              }
                            }
                          },
                          [_vm._v("Remove Content")]
                        ),
                        _vm._v(" \n        "),
                        _c(
                          "gov-button",
                          {
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.moveUp(sectionId, index)
                              }
                            }
                          },
                          [_vm._v("Move up")]
                        ),
                        _vm._v(" \n      ")
                      ],
                      1
                    )
                  : _vm._e(),
                index < section.content.length - 1
                  ? _c(
                      "gov-button",
                      {
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.moveDown(sectionId, index)
                          }
                        }
                      },
                      [_vm._v("Move down")]
                    )
                  : _vm._e()
              ],
              1
            )
          })
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }