var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { tag: "h4", size: "s" } }, [
        _vm._v("Video Embed")
      ]),
      _vm.error !== null
        ? _c("gov-error-message", {
            attrs: { for: _vm.id },
            domProps: { textContent: _vm._s(_vm.error) }
          })
        : _vm._e(),
      _c("ck-text-input", {
        attrs: {
          value: _vm.video.title,
          id: "video_title",
          label: "Title",
          type: "text",
          error: null
        },
        on: {
          input: function($event) {
            return _vm.onInput("title", $event)
          }
        }
      }),
      _c(
        "ck-text-input",
        {
          attrs: {
            value: _vm.video.url,
            id: "video_url",
            label: "Video URL",
            type: "url",
            error: null
          },
          on: {
            input: function($event) {
              return _vm.onInput("url", $event)
            }
          }
        },
        [
          _c(
            "template",
            { slot: "hint" },
            [
              _c("gov-hint", { attrs: { for: "video_embed" } }, [
                _vm._v(
                  "\n        Youtube and Vimeo links are accepted.\n      "
                )
              ])
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }