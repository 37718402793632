var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { tag: "h4", size: "s" } }, [
        _vm._v("Call to action")
      ]),
      _vm.error !== null
        ? _c("gov-error-message", {
            attrs: { for: _vm.id },
            domProps: { textContent: _vm._s(_vm.error) }
          })
        : _vm._e(),
      _c("ck-text-input", {
        attrs: {
          value: _vm.callToAction.title,
          id: "cta-title",
          label: "Title",
          type: "text",
          error: null
        },
        on: {
          input: function($event) {
            return _vm.onInput("title", $event)
          }
        }
      }),
      _c("ck-textarea-input", {
        attrs: {
          value: _vm.callToAction.description,
          id: "cta-description",
          label: "Description",
          error: null
        },
        on: {
          input: function($event) {
            return _vm.onInput("description", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.callToAction.url,
          id: "cta-url",
          label: "Link address",
          type: "text",
          error: null
        },
        on: {
          input: function($event) {
            return _vm.onInput("url", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.callToAction.buttonText,
          id: "cta-button-text",
          label: "Button text",
          type: "text",
          error: null
        },
        on: {
          input: function($event) {
            return _vm.onInput("buttonText", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }