var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.nodes, function(node) {
      return _vm.showCheckBox(node)
        ? _c(
            "gov-checkbox",
            {
              key: node.id,
              class: { "govuk-checkboxes__item--nested": _vm.nested },
              attrs: {
                value: _vm.checked.includes(node.id),
                id: node.id,
                name: node.id,
                label: node.name,
                disabled: _vm.disabled
              },
              on: {
                input: function($event) {
                  return _vm.onUpdate({ node: node, enabled: $event })
                }
              }
            },
            [
              _vm._t("default", null, { node: node }),
              node.children && node.children.length
                ? _c("ck-node-checkboxes", {
                    key: node.children[0].id,
                    attrs: {
                      nodes: node.children,
                      checked: _vm.checked,
                      filteredNodeIds: _vm.filteredNodeIds,
                      disabled: _vm.disabled,
                      nested: true
                    },
                    on: { update: _vm.onChildUpdate },
                    scopedSlots: _vm._u(
                      [
                        _vm._l(_vm.$scopedSlots, function(_, name) {
                          return {
                            key: name,
                            fn: function(slotProps) {
                              return [
                                _vm._t(name, null, { node: slotProps.node })
                              ]
                            }
                          }
                        })
                      ],
                      null,
                      true
                    )
                  })
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }